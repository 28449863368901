// This file loads necessary polyfills for older browsers
// It should be loaded before any other webpack entrypoints

import 'core-js/stable';
import 'picturefill';
import 'raf/polyfill';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
// https://zengabor.github.io/zenscroll/
// This enables CSS scroll-behavior for Safari and more.
import zenscroll from 'zenscroll';

function addOffsetForAnchorLinks() {
  if (document.querySelector('#gusto-navbar')) {
    const offset = document.querySelector('#gusto-navbar').offsetHeight;
    zenscroll.setup(100, offset);
  }
}

window.addEventListener(
  'load',
  () => {
    addOffsetForAnchorLinks();
  },
  { once: true }
);

// IE11 does not natively support "new Event()"
// This polyfill adds support for "new CustomEvent()", which we should use instead of "new Event()"
// MDN source: https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
// Slightly tweaked to staisfy our linter.
(() => {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, params) {
    const newParams = params || { bubbles: false, cancelable: false, detail: null };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, newParams.bubbles, newParams.cancelable, newParams.detail);
    return evt;
  }

  window.CustomEvent = CustomEvent;
  return 0;
})();

// Polyfill for the `closest` function.
// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest) {
  Element.prototype.closest = s => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let el = this;
    do {
      if (el.matches(s)) {
        return el;
      }
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
